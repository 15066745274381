import React from "react";
import locales from "../constants";
import { Layout, ContactFormDark } from "../components";
import { Link } from "gatsby";
import $ from "jquery";

const AudytKampaniiGooglePage = ({ location: { pathname } }) => {
  const freeAuditBtnAction = (e) => {
    e.preventDefault();
    $('#audytType option[value="Darmowy audyt"]').attr("selected", "selected");
    $("#audytType").css("color", "black");
  };
  const advancedAuditBtnAction = (e) => {
    e.preventDefault();
    $('#audytType option[value="Zaawansowany audyt"]').attr(
      "selected",
      "selected"
    );
    $("#audytType").css("color", "black");
  };

  if (typeof window !== `undefined`) {
    $(window).scroll(function () {
      if ($("#inViewport, #inViewport2, #inViewport3").isOnScreen()) {
        setTimeout(function () {
          $("#inViewport, #inViewport2, #inViewport3").addClass("active");
        }, 500);
      } else {
        $("#inViewport, #inViewport2, #inViewport3").removeClass("active");
      }
    });

    $.fn.isOnScreen = function () {
      var win = $(window);

      var viewport = {
        top: win.scrollTop(),
        left: win.scrollLeft(),
      };
      viewport.right = viewport.left + win.width();
      viewport.bottom = viewport.top + win.height();

      var bounds = this.offset();
      bounds.right = bounds.left + this.outerWidth();
      bounds.bottom = bounds.top + this.outerHeight();

      return !(
        viewport.right < bounds.left ||
        viewport.left > bounds.right ||
        viewport.bottom < bounds.top ||
        viewport.top > bounds.bottom
      );
    };
  }
  return (
    <Layout
      seo={{
        title: "Audyt, analiza kampanii Facebook Ads oraz Google Ads",
        description:
          "Odkryj jak zwiększyć skuteczność swoich kampanii Facebook Ads i Google Ads dzięki naszemu profesjonalnemu audytowi. Analizujemy każdy element Twojego konta, aby zapewnić Ci najlepsze rezultaty. Nasze audyty Google Ads oraz Facebook Ads przeprowadzane są przez doświadczonych, certyfikowanych specjalistów.",
        href: pathname,
        lang: "pl",
      }}
    >
      <span className="anchor" id="home"></span>
      <div className="subpage-header subpage-header--single-offer">
        <h1 className="subpage-header__title">
          Audyt kampanii Google/ <br />
          <span>Facebook Ads</span>
        </h1>
      </div>

      <div className="single-offer-menu-container">
        <div className="row">
          <div className="col-lg-3">
            <Link to="/uslugi/#ppc">
              <div className="back-wrapper">
                <span className="back-wrapper__arrow"></span>
                <p className="back-wrapper__title">Usługi</p>
              </div>
            </Link>
            <p className="nav-title">Specjalizacje:</p>
            <ul>
              <li className="current">
                <a href="#home">
                  Audyt kampanii <br /> Google/Facebook Ads
                </a>
              </li>
              <li>
                <Link to="/kampanie-googleads-search/">
                  Kampanie Google Ads search
                </Link>
              </li>
              <li>
                <Link to="/kampanie-wideo-na-youtube/">
                  Kampanie Youtube Ads
                </Link>
              </li>
              <li>
                <Link to="/kampanie-w-sieci-reklamowej-gdn/">Kampanie GDN</Link>
              </li>
              <li>
                <Link to="/kampanie-produktowe-product-listing-ads/">
                  Kampanie PLA
                </Link>
              </li>
              <li>
                <Link to="/tiktok-ads/">Kampanie TikTok Ads</Link>
              </li>
              <li>
                <Link to="/kampanie-facebook-ads/">Kampanie Facebook Ads</Link>
              </li>
              <li>
                <Link to="/kampanie-na-instagramie/">
                  Kampanie na Instagramie
                </Link>
              </li>
              <li>
                <Link to="/kampanie-linkedin-ads/">Kampanie LinkedIn</Link>
              </li>
              <li>
                <Link to="/banery-statyczne-i-dynamiczne/">
                  Banery statyczne i dynamiczne
                </Link>
              </li>
              <li>
                <Link to="/kampanie-zagraniczne/">
                  Kampanie PPC na rynki zagraniczne
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <section className="single-offer-section-text-left single-offer-section-text-left--cat-2">
        <div className="row">
          <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">Partner w sukcesie</h2>
                <p className="text-col__desc">
                  Zastanawiasz się, czy Twoja kampania Google / Facebook Ads
                  jest dobrze prowadzona lub czy może osiągać lepsze wyniki?
                  Spójrz na kampanię Google / Facebook Ads z nowej perspektywy.
                  Zweryfikuj błędy, które mogą przepalać środki i sprawdź
                  niewykorzystane obszary rozwoju Twojej kampanii. <br />
                  <br />
                  Nasze audyty przeprowadzane są przez doświadczonych,
                  certyfikowanych specjalistów, którzy każdego dnia, dzięki
                  optymalizacji kampanii, pomagają w rozwoju dziesiątek firm z
                  różnych branż.
                </p>
              </div>
              <div className="col-lg-6 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/images/audyt-fb-google-img-1.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-text-lines">
        <div className="row">
          <div className="col-xl-8 offset-lg-3 text-col">
            <h2 className="text-col__title title title--1" id="inViewport">
              Zweryfikuj ewentualne błędy
            </h2>
            <h2 className="text-col__title title title--2" id="inViewport2">
              Wykorzystaj najnowsze rozwiązania
            </h2>
            <h2 className="text-col__title title title--3" id="inViewport3">
              Sprawdź nowe możliwości
            </h2>
          </div>
        </div>
      </section>
      <section className="single-offer-section-big-img single-offer-section-big-img--audyt-google-fb">
        <div className="row">
          <div className="col-xl-7 col-lg-8 offset-lg-4">
            <h2 className="single-offer-section-big-img__title">
              Dokładna Analiza
            </h2>
            <div className="image-container">
              <img
                className="single-offer-section-big-img__image image image--desktop"
                src={require("../assets/images/audyt-kampanii-graph.png")}
                alt=""
              />
              <img
                className="single-offer-section-big-img__image image image--mobile"
                src={require("../assets/images/audyt-kampanii-graph-mobile.png")}
                alt=""
              />
              <p className="image-container__text text text--1">
                Budowa i struktura kont
              </p>
              <p className="image-container__text text text--2">
                Implementacja analityki (Google Analytics, Google Merchant
                Center, pixele)
              </p>
              <p className="image-container__text text text--3">
                Grupy <br /> odbiorców
              </p>
              <p className="image-container__text text text--4">
                Listy remarketingowe i cele
              </p>
              <p className="image-container__text text text--5">
                Ustawienia kampanii i zestawów reklam
              </p>
              <p className="image-container__text text text--6">Rozszerzenia</p>
              <p className="image-container__text text text--7">
                Słowa kluczowe
              </p>
              <p className="image-container__text text text--8">Reklamy</p>
              <p className="image-container__text text text--9">
                Analiza wykorzystania najnowszych rekomendacji
              </p>
              <p className="image-container__text text text--10">
                <strong>Zalecenia!</strong>
              </p>
              <ul className="big-bullets list-mobile">
                <li>Budowa i struktura kont</li>
                <li>
                  Implementacja analityki (Google Analytics, Google Merchant
                  Center, pixele)
                </li>
                <li>Grupy odbiorców</li>
                <li>Listy remarketingowe i cele</li>
                <li>Ustawienia kampanii i zestawów reklam</li>
                <li>Rozszerzenia</li>
                <li>Słowa kluczowe</li>
                <li>Reklamy</li>
                <li>Analizaca wykorzystania najnowszych rekomendacji</li>
                <li>
                  <strong>Zalecenia!</strong>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-table">
        <div className="row">
          <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3">
            <h2 className="single-offer-section-table__title">
              Wybierz rozwiązanie dla siebie
            </h2>
            <div className="table-container">
              <div className="custom-row">
                <div className="small-col">
                  <div className="table-container">
                    <h2 className="table-container__title title title--turqoise">
                      Bezpłatny audyt kampanii
                    </h2>
                    <p className="table-container__text">
                      Chcesz wstępnie dowiedzieć się, w jakiej kondycji jest
                      Twoja kampania i wskazać jej najważniejsze obszary do
                      poprawy?
                      <br />
                      <br />
                      Skorzystaj z naszej usługi wstępnego audytu kampanii.
                    </p>
                    <a
                      href="#kontakt"
                      onClick={freeAuditBtnAction}
                      className="btn-box btn"
                    >
                      <strong>skontaktuj się z nami</strong>
                    </a>
                  </div>
                </div>
                <div className="big-col">
                  <div className="table-container">
                    <h2 className="table-container__title title title--violet">
                      Zaawansowany audyt <br /> kampanii
                    </h2>
                    <p className="table-container__text">
                      Rozwiń kampanię na wyżyny jej możliwości!
                      <br />
                      <br />
                      Zweryfikujemy obecne ustawienia kampanii pod kątem
                      realizacji Twoich celów biznesowych, ocenimy potencjał
                      rozwoju i niewykorzystywane możliwości. Zaawansowany audyt
                      odpowie na pytanie nie tylko “jak jest”, ale wskaże
                      dokładne obszary, które wymagają poprawy i zalecenia,
                      które składają się na odpowiedź “jak powinno być”.
                    </p>
                    <ul className="big-bullets">
                      <li>
                        Zaawansowana analiza kampanii, analityki i
                        narzędziwspierających
                      </li>
                      <li>Rozbudowane zalecenia</li>
                      <li>Rekomendacje dotyczące najnowszych rozwiązań</li>
                      <li>Analiza rentowności kampanii e-commerce</li>
                      <li>Konsultacje ze specjalistą</li>
                      <li>Wdrożenie zmian (opcjonalnie)</li>
                      <li>Analiza efektów zmian po ustalonym terminie</li>
                    </ul>
                    <a
                      href="#kontakt"
                      onClick={advancedAuditBtnAction}
                      className="btn-box btn"
                    >
                      <strong>zapytaj o wycenę</strong>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <span className="anchor" id="kontakt"></span>
      <section className="single-offer-section-contact">
        <div className="form-wrapper">
          <h2 className="single-offer-section-contact__title mb-40">
            Skontaktuj się z nami! <br />
            Wypełnij <span>formularz</span>
          </h2>
          <ContactFormDark
            locales={locales["pl"]}
            select={true}
            messageRequired={false}
          />
        </div>
        <img
          className="img-fluid single-offer-section-contact__hand"
          src={require("../assets/images/home-contact-hand-right.png")}
          alt=""
        />
      </section>
    </Layout>
  );
};
export default AudytKampaniiGooglePage;
